<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Actualizacion DAE" collection="actualizacion_dae" templateTable="TableActualizacionDae" templateTableModal="TableActualizacionDaeModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        DNIoPasaporte_c: 'Pasaporte',
        RUToRUTProvisionalDeContacto_c: 'RUT',
        PrimaryContactEmailAddress: 'E-mail',
        PrimaryPhoneCountryCode: 'Código celular',
        PrimaryPhoneAreaCode: 'Código teléfono',
        PrimaryPhoneNumber: 'Número celular',
        TipoDeTelefono_c: 'Tipo de teléfono',
        FormularioDeOrigen_c: 'Formulario de origen',
        Institucion_c: 'Institución',
        createdAt: 'Fecha'
      },
      query: {
        collection: 'formulario_retiros',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
